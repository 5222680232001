import React from "react"
import Footer from "../components/Footer"
import Contact from "../components/Contact"
import Navbar from "../components/Navbar"

function Home() {
  return (
    <div  class = "">
      <Navbar/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default Home
